<template>
<div class="tw-w-full tw-flex tw-flex-col">
  <home-navbar />
  <section class="section-one" :style="{backgroundImage:`url(${teamPic})`}">
       <h6 class="section-one-header">Stay up to date with happenings at B4B and the international basketball community </h6>
  </section>
  <section class="tw-hidden tw-w-full tw-pt-20">
    <blog-post />
  </section>

  <Footer/>
</div>
</template>

<script>
import HomeNavbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import teamPic from "@/assets/teampic.png"
import BlogPost from "@/components/reuseables/Blogpost";
export default {
  name: "BlogView",
  components: {BlogPost, Footer, HomeNavbar},
  data(){
    return{
      teamPic
    }
  }
}
</script>

<style scoped lang="scss">
.section-one{
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  align-items: center;
  justify-content: center;
  margin-top: 6rem;
}
.section-one-header{
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 70px;
  text-align: center;
  color: #FFFFFF;
  width: 70%;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
</style>