<template>
  <div class="blogpost-div">
    <v-card elevation="0" class="tab-card">
      <v-tabs v-model="tab">
        <v-tabs-slider color="#F66B05"></v-tabs-slider>
        <v-tab href="#all"> All </v-tab>
        <v-tab href="#workshops"> Workshops </v-tab>

        <v-tab href="#fun times"> Fun times </v-tab>
        <v-tab href="#fundraiser"> Fundraisers </v-tab>
        <v-tab href="#innovation"> Innovations </v-tab>
      </v-tabs>
    </v-card>

    <div class="tw-flex tw-flex-wrap post-div">
      <div class="post-card" v-for="(blogpost, index) in posts" :key="index">
        <v-card class="mx-auto my-12" elevation="0">
          <div class="scroll">
            <div class="imagediv">
              <v-img height="350" :src="blogpost.image">
                <div class="date">{{ blogpost.date }}</div>
              </v-img>
            </div>
            <v-card-title>{{ blogpost.title }}</v-card-title>
            <v-chip
              label
              v-show="blogpost.section === 'Fundraiser'"
              color="blue darken-1"
            >
              {{ blogpost.section }}
            </v-chip>
            <v-chip
              label
              v-show="blogpost.section === 'Workshops'"
              color="green lighten-1"
            >
              {{ blogpost.section }}
            </v-chip>
            <v-chip
              label
              v-show="blogpost.section === 'Fun Times'"
              color="pink darken-1"
            >
              {{ blogpost.section }}
            </v-chip>

            <v-card-text>
              {{ blogpost.content }}
            </v-card-text>
          </div>
          <v-card-actions>
            <v-btn color="blue darken-4" text @click="routeToPage">
              Read more
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>

  </div>
</template>

<script>
import pic2 from "@/assets/post.png";
import pic1 from "@/assets/post.png";
import pic4 from "@/assets/post.png";

export default {
  name : "BlogPost",
  data() {
    return {
      blogposts: [
        {
          date: "Fri Feb. 5th",
          image: pic2,
          section: "Fundraisers",
          title: "Raising Funds For Millions",
          content:
              "We’re inspired by a vision of millions of road commuters and the thousands of businesses across the country who use the road to carry out their daily activities and businesses",
        },
        {
          date: "Wed Jan. 5th",
          image: pic4,
          section: "Fundraisers",
          title: "Raising Funds For Millions",
          content:
              "We’re inspired by a vision of millions of road commuters and the thousands of businesses across the country who use the road to carry out their daily activities and businesses",
        },
        {
          date: "Fri Feb. 5th",
          image: pic1,
          section: "Fundraisers",
          title: "Raising Funds For Millions",
          content:
              "We’re inspired by a vision of millions of road commuters and the thousands of businesses across the country who use the road to carry out their daily activities and businesses",
        },

      ],
      tab: null,
      posts: [],
    };
  },
  watch: {
    tab: {
      handler: function (val) {
        if (val === "all") {
          this.posts = this.blogposts;
        } else {
          this.posts = this.blogposts.filter(
            (post) => post.section.toLowerCase() === val
          );
        }
      },
      immediate: true,
    },
  },
  methods: {
    routeToPage() {
      if (this.$route.name !== "Blogpages") {
        this.$router.push({ name: "Blogpages" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tabs {

  @media screen and (max-width: 1264px) {
    padding-left: 5%;
  }
  @media screen and (max-width: 768px) {
    padding-left: 0% !important;
  }
}

.theme--light.v-tabs > .v-tabs-bar,
.v-application .primary--text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(79, 79, 79, 0.452) !important;
  @media screen and (max-width: 600px) {
    line-height: 20px;
  }
}

.v-tab {
  min-width: 0 !important;
  text-transform: none !important;
  color: rgba(79, 79, 79, 0.473) !important;
}
.blogpost-div {
  min-height: 100vh;
  padding-left: 15rem;
  @media screen and (max-width: 1264px) {
    padding-left: 2rem;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    background-color: #fdfffc;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}
.post-div {
  width: 100%;
  padding: 3% 9% 7% 0;
  @media screen and (max-width: 1264px) {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
}

.date {
  position: absolute;
  bottom: 0;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: #ffffff;
  padding: 10px;
  background: rgba(0, 74, 173, 0.5);
  border-radius: 0 0 0 15px;
  right: 0;
}

.post-card {
  width: 400px;
  margin: 3%;
  @media screen and (max-width: 600px) {
    width: 350px;
    padding-left: 20px;
  }
  @media screen and (min-width: 1800px) {
    margin: 2% 3%;
  }
}

.imagediv {
  position: relative;
}

.v-image {
  border-radius: 15px;
}

.v-chip {
  margin-left: 15px;
}

.v-chip__content {
  font-family: "Inter";
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff !important;
}

.v-card__title {
  font-family: "Inter",sans-serif;
  font-style: normal;
  font-weight: 500;
  word-break: initial;
  font-size: 24px;
  color: #4F4F4F;
  padding: 16px 10px;
}
.v-tabs-bar__content {
  margin: 0 auto;
}
.v-card__text {
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #4F4F4F;
}

@media screen and (max-width: 463px) {
  .blogpost-div {
    padding: 0 3% !important;
  }
  .v-image__image,
  .v-card__text {
    width: 80%;
  }
}
::v-deep .theme--light.v-sheet {
  background-color: transparent !important;
}
.scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 650px;
  height: 652px;
}
.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}
.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 20px !important;
  border-radius: 6px;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.theme--light.v-chip:not(.v-chip--active) {
 background-color: #4D87D4;
  color: #FFFFFF;
}

.v-card__actions > .v-btn.v-btn {
  text-transform: none;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #4D87D4;
}

::v-deep .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
  display: none !important;
}
</style>